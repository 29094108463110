// Destination Edit Page Component
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Layout } from '../../components/Layout';
import { tablist } from '../../components/Layout/helpers/options';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';

import axios from '../../api/axios';

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: scroll;
`;

const PageContent = styled.div`
	background-color: #f6f8f9;
    display: flex;
    flex-direction: row;
    min-height: 100%;
	width: 100%;
	padding: 20px 200px 20px 200px;
`;

const DataSourceContent = styled.div`
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 4px 9px -3px rgba(102,136,153,.15);
	background-color: #fff !important;
	padding: 15px !important;
`;

const FormRow = styled.div`
    min-width: 284px;
    max-width: 284px;
    margin-bottom: 25px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Title = styled.div`
    font-family: 'Roboto Condensed', sans-serif, Arial;
	font-weight: 500;
    font-size: 14px;
    color: #7a7a7a;
    padding-bottom: 8px;
`;

const Info = styled.div`
	margin-bottom: 10px !important;
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img`
    width: 64px;
    height: 64px;
    margin-right: 5px;
`;

const Name = styled.h3`
    font-size: 23px;
    color: #323232;
    font-family: 'Roboto Condensed', sans-serif, Arial;
    margin: 0 !important;
    text-overflow: ellipsis;
    overflow: hidden;
`;

// Main Component
const DestinationEdit = (props) => {
	const { dataSourceId } = props;
	const [Datasource, setDatasource] = useState({});
	const dataSource = Datasource;
	const options = (dataSource && dataSource.options) || [];

	useEffect(() => {
		axios
			.get(`/api/data_sources/${dataSourceId}`)
			.then((res) => {
				if (res && res.data) {
					setDatasource(res.data);
				}

				return Promise.resolve({});
			})
			.catch((err) => Promise.resolve(err));
	}, []);

	return (
		<Wrapper>
			<Layout
				tablist={tablist}
				activeMenu="destination"
			/>
			<PageContent>
				<DataSourceContent>
					<Info>
						<Image alt={dataSource.name} src={dataSource.imgSrc} />
						<Name>{dataSource.name}</Name>
					</Info>
					<Content>
						<FormRow>
							<Title>Name</Title>
							<Input
								value={dataSource.name}
								type="text"
								variant="data-source"
							/>
						</FormRow>
						<FormRow>
							<Title>Host</Title>
							<Input
								value={options.host}
								type="text"
								variant="data-source"
							/>
						</FormRow>
						<FormRow>
							<Title>Port</Title>
							<Input
								value={options.port}
								type="text"
								variant="data-source"
							/>
						</FormRow>
						<FormRow>
							<Title>User</Title>
							<Input
								value={options.user}
								type="text"
								variant="data-source"
							/>
						</FormRow>
						<FormRow>
							<Title>Password</Title>
							<Input
								value={options.password}
								type="password"
								variant="data-source"
							/>
						</FormRow>
						<FormRow>
							<Title>SSL Mode</Title>
							<Input
								placeholder="Prefer"
								type="text"
								variant="data-source"
							/>
						</FormRow>
						<FormRow>
							<Title>Database Name</Title>
							<Input
								value={options.dbname}
								type="text"
								variant="data-source"
							/>
						</FormRow>
						<FormRow>
							<Button
								title="Save"
								variant="connect-view"
							/>
						</FormRow>
					</Content>
				</DataSourceContent>
			</PageContent>
		</Wrapper>
	);
};

DestinationEdit.propTypes = {
	dataSourceId: PropTypes.string,
};

DestinationEdit.defaultProps = {
	dataSourceId: '',
};

export default React.memo(DestinationEdit, isEqual);
