// Viewer Component
import React from 'react';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import moment from 'moment';

import { Button } from '../../../components/Button';
import { Layout } from '../../../components/Layout';
import { Skeleton } from '../../../components/Skeleton';
import { ErrorMessage } from '../../../components/ErrorMessage';

import { tablist } from '../../../components/Layout/helpers/options';

const Wrapper = styled.div`
    width: 100%;
    min-height: 100%;
`;

const PageContent = styled.div`
    padding: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Content = styled.div`
    border: 1px solid #000;
    cursor: pointer;
    width: 170px;
    margin-right: 60px;
    padding: 4px 5px 4px 5px;
`;

const Image = styled.div`
    width: 18px;
    height: 18px;
    margin-right: 9px;
`;

const Info = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 12px;
`;

const Title = styled.div`
    align-self: center;
    color: #4c5a67;
	font-family: 'Roboto Condensed', sans-serif, Arial;
	font-weight: 600;
	font-size: 10px;
    line-height: 12px;
`;

const TitleInfo = styled.div`
    color: #000002;
	font-family: 'Roboto Condensed', sans-serif, Arial;
	font-weight: 700;
	font-size: 15px;
    line-height: 17px;
`;

const SyncInfo = styled.p`
    color: #000002;
	font-family: 'Roboto Condensed', sans-serif, Arial;
	font-weight: 600;
	font-size: 12px;
    line-height: 14px;
    margin-top: 4px;
`;

const FooterInfo = styled.div`
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;

const StatusImage = styled.img`
    width: 22px;
    height: 22px;
`;

const ImageContent = styled.div`
    flex-grow: 1;
`;

const ButtonRow = styled(Content)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 108px;
`;

const Message = styled.div`
    align-self: center;
    color: #000002;
    background-color: #fff;
    font-family: 'Roboto Condensed', sans-serif, Arial;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    width: 110px;
`;

// Get the Data
const REF_PIPELINE = gql`
query {
    pipeline (where:{created_by: {_eq: "28ed9102-2b5b-466e-a850-4b2ff1b354ea"}}) {
		id
		name
		description
		last_ran_at
		status
		source{
		  id
		  name
		  image_url
		}
		destination {
			name
		}
	}	
   }
`;

// PipelineInfo Component
const PipelineInfo = ({ pipeline }) => { // eslint-disable-line
    const { source } = pipeline; // eslint-disable-line
	const syncInfo = `Last ran ${moment(pipeline.last_ran_at).startOf('day').fromNow()}`;

	/* Handler Functionn */
	const onClickPipeline = () => {
		navigate(`/pipelines-view/${pipeline.id}`);
	};

	return (
		<Content onClick={onClickPipeline}>
			<Info>
				<Image>
					<img src={source.image_url} alt="img" />
				</Image>
				<Title>{source.name}</Title>
			</Info>
			<TitleInfo>{pipeline.name}</TitleInfo>
			<SyncInfo>Syncing...</SyncInfo>
			<FooterInfo>
				<ImageContent>
					{pipeline.status === true
						? <StatusImage src="/images/start_icon.png" alt="start" />
						: <StatusImage src="/images/stop_icon.png" alt="stop" />}
				</ImageContent>
				<Message>{syncInfo}</Message>
			</FooterInfo>
		</Content>
	);
};

// Viewer Component
const Viewer = () => {
	const { loading, error, data } = useQuery(REF_PIPELINE);
	const pipelines = (data && data.pipeline) || [];

	/* Handler Functionn */
	const onClick = () => {
		navigate('/pipeline/create');
	};

	if (loading) {
		return <Skeleton />;
	}

	if (error) {
		return <ErrorMessage error={error} />;
	}

	return (
		<Wrapper>
			<Layout
				tablist={tablist}
				activeMenu="pipeline"
			/>
			<PageContent>
				{pipelines.map((pipeline) =>
					<PipelineInfo pipeline={pipeline} />)}
				<ButtonRow>
					<Button
						title="Create"
						leftIcon="add"
						variant="header"
						onClick={onClick}
					/>
				</ButtonRow>
			</PageContent>
		</Wrapper>
	);
};

export default React.memo(Viewer, isEqual);
