// Skeleton Component
import React from 'react';
import isEqual from 'react-fast-compare';
import { Skeleton } from '@chakra-ui/core';
import styled from 'styled-components';

const WrapperComp = styled.div`
    margin: 100px;
    box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
`;

// Main Component
const SkeletonComponent = () => (
	<WrapperComp>
		<Skeleton colorStart="#F4F4F4" height="20px" />
	</WrapperComp>
);

export default React.memo(SkeletonComponent, isEqual);
