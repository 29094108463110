/* eslint-disable import/prefer-default-export */
import SchemaBrowser from '../schema_browser';
// import QueryEditor from '@/components/queries/QueryEditor';
// import DatabricksSchemaBrowser from './databricks/DatabricksSchemaBrowser';

import { registerEditorComponent, getEditorComponents, QueryEditorComponents } from './editorComponents';

// default
registerEditorComponent(QueryEditorComponents.SCHEMA_BROWSER, SchemaBrowser);
// registerEditorComponent(QueryEditorComponents.QUERY_EDITOR, QueryEditor);

// // databricks
// registerEditorComponent(QueryEditorComponents.SCHEMA_BROWSER, DatabricksSchemaBrowser, ['databricks']);

export { getEditorComponents };
