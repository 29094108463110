// Connect Right View Component
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEqual from 'react-fast-compare';

import { Button } from '../../../components/Button';
import { Input } from '../../../components/Input';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ImageRow = styled.div`
`;

const FormRow = styled.div`
    min-width: 402px;
    max-width: 402px;
    margin-top: 58px;
`;

const FormRowInfo = styled(FormRow)`
    margin-top: 24px;
`;

const ButtonRow = styled.div`
    margin-top: 24px;
    margin-left: 285px;
`;

const ConnectRightView = (props) => {
	const { onMenuItem, item } = props;
	const [userName, setUserName] = useState('');
	const [url, setUrl] = useState(item.url);

	const onClickMenuItem = () => {
		item.userName = userName;
		item.url = url;

		if (onMenuItem) {
			onMenuItem('configure', item || {});
		}
	};

	/* Handler Functionn */
	const handleChange = (value) => {
		setUrl(value);
	};

	const handleChangeUserName = (value) => {
		setUserName(value);
	};

	return (
		<Wrapper>
			<ImageRow>
				<img src={item.image_url} alt="connect" />
			</ImageRow>
			<FormRow>
				<Input
					value={url}
					variant="connect"
					onChange={handleChange}
				/>
			</FormRow>
			<FormRowInfo>
				<Input
					value={userName}
					variant="connect"
					placeholder="UserName"
					onChange={handleChangeUserName}
				/>
			</FormRowInfo>
			<ButtonRow>
				<Button
					title="Continue"
					rightIcon="arrow-forward"
					variant="connect-view"
					onClick={onClickMenuItem}
				/>
			</ButtonRow>
		</Wrapper>
	);
};

ConnectRightView.propTypes = {
	item: PropTypes.object, // eslint-disable-line
	onMenuItem: PropTypes.func,
};

ConnectRightView.defaultProps = {
	item: null,
	onMenuItem: null,
};

export default React.memo(ConnectRightView, isEqual);
