// Cofigure Right View Component
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEqual from 'react-fast-compare';

import { syncList, syncTimeList } from '../helpers/options';

import { CheckBox } from '../../../components/CheckBox';
import { Button } from '../../../components/Button';
import { Select } from '../../../components/Select';

const Wrapper = styled.div`
    width: 100%
`;

const UserInfo = styled.div`
    display: flex;
`;

const Image = styled.img`
    width: 46px;
    height: 32px;
`;

const TitleInfo = styled.div`
    margin-left: 28px;
`;

const Title = styled.div`
    color: #000000;
	font-family: 'Roboto Condensed', sans-serif, Arial;
	font-weight: 600;
	font-size: 14px;
    line-height: 16px;
`;

const Info = styled(Title)`
    color: #acb8c8;
    font-size: 12px;
    line-height: 14px;
`;

const ContentInfo = styled.div`
    margin-top: 50px;
    margin-bottom: 64px;
`;

const Heading = styled(Title)`
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 18px;
`;

const Content = styled.div`
    margin-bottom: 6px;
`;

const SyncInfo = styled.div`
    margin-bottom: 6px;
`;

const SelectComp = styled.div`
    width: 300px;
    margin-bottom: 26px;
`;

const ButtonRow = styled.div`
    margin-top: 58px;
`;

const ConfigureRightView = (props) => {
	const { item, options } = props;

	const url = item && item.url && item.url.replace('https://www.', '');

	return (
		<Wrapper>
			<UserInfo>
				<Image src={item.image_url} alt="connect" />
				<TitleInfo>
					<Title>{item.userName}</Title>
					<Info>{url}</Info>
				</TitleInfo>
			</UserInfo>
			<ContentInfo>
				<Heading>Select the entities that need to be synced</Heading>
				{options.map((opt) => (
					<Content>
						<CheckBox
							isChecked={opt.isChecked}
							children={opt.title}
						/>
					</Content>
				))}
			</ContentInfo>
			<SyncInfo>
				<Heading>Sync From</Heading>
				<SelectComp>
					<Select
						options={syncList}
					/>
				</SelectComp>
				<Heading>Sync Time</Heading>
				<SelectComp>
					<Select
						options={syncTimeList}
					/>
				</SelectComp>
			</SyncInfo>
			<ButtonRow>
				<Button
					title="Continue"
					rightIcon="arrow-forward"
					variant="connect-view"
				/>
			</ButtonRow>
		</Wrapper>
	);
};

ConfigureRightView.propTypes = {
	item: PropTypes.object, // eslint-disable-line
	options: PropTypes.array, // eslint-disable-line
	onMenuItem: PropTypes.func,
};

ConfigureRightView.defaultProps = {
	item: null,
	options: [],
	onMenuItem: null,
};

export default React.memo(ConfigureRightView, isEqual);
