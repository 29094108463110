// Destination Component
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import { Layout } from '../../components/Layout';
import { tablist } from '../../components/Layout/helpers/options';
import { DataSources } from '../../components/DataSources';

import axios from '../../api/axios';

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: scroll;
`;

const PageContent = styled.div`
	background-color: #f6f8f9;
    display: flex;
    flex-direction: row;
    min-height: 100%;
	width: 100%;
	padding: 20px 200px 20px 200px;
`;

// Main Component
const Destination = () => {
	const [DatasourceList, setDatasourceList] = useState([]);

	const onClickQuery = (id) => {
		navigate(`/queries/${id}/schema`);
	}

	const onClick = (id) => {
		navigate(`/destinations/${id}`);
	}

	useEffect(() => {
		axios
			.get('/api/data_sources')
			.then((res) => {
				if (res && res.data.length !== 0) {
					setDatasourceList(res.data);
				}

				return Promise.resolve([]);
			})
			.catch((err) => {
				return Promise.resolve(err);
			});
	}, []);

	return (
		<Wrapper>
			<Layout
				tablist={tablist}
				activeMenu="destination"
			/>
			<PageContent>
				{DatasourceList.map((opt) => (
					<DataSources dataSource={opt} onClick={onClick} onClickQuery={onClickQuery} />
				))}
			</PageContent>
		</Wrapper>
	);
};

export default React.memo(Destination, isEqual);
