// Text Component
import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { Text } from '@chakra-ui/core';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;

    .css-fzcsno {
        color: #000;
        font-family: 'Roboto Condensed', sans-serif, Arial;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
    }
`;

const WrapperCompPipeline = styled.div`
    .css-fzcsno {
        color: #866210;
        font-family: 'Roboto Condensed', sans-serif, Arial;
        font-weight: 400;
        font-size: 19px;
        line-height: 22px;
    }
`;

const WrapperCompConnect = styled(WrapperCompPipeline)`
    .css-fzcsno {
        color: #197eb6;
    }
`;

const WrapperCompConfigure = styled(WrapperCompPipeline)`
    .css-fzcsno {
        color: #c8636f;
    }
`;

// Main Component
const TextComponent = (props) => {
	const { title, varient } = props;

	let WrapperComp = Wrapper;

	if (varient === 'pipeline') {
		WrapperComp = WrapperCompPipeline;
	} else if (varient === 'connect') {
		WrapperComp = WrapperCompConnect;
	} else if (varient === 'configure') {
		WrapperComp = WrapperCompConfigure;
	}

	return (
		<WrapperComp>
			<Text>{title}</Text>
		</WrapperComp>
	);
};

TextComponent.propTypes = {
	title: PropTypes.string,
	varient: PropTypes.oneOf(['pipeline', 'connect', 'configure']),
};

TextComponent.defaultProps = {
	title: '',
	varient: '',
};

export default React.memo(TextComponent, isEqual);
