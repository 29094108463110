/* eslint-disable camelcase */
// Pipeline View Component
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';

import { Skeleton } from '../../../components/Skeleton';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { Table } from '../../../components/Table';
import { Layout } from '../../../components/Layout';
import { tablist } from '../../../components/Layout/helpers/options';
import { Switch } from '../../../components/Switch';
import { Icon } from '../../../components/Icon';

const WrapperComp = styled.div`
`;

const Content = styled.div`
    padding: 16px 22px 
`;

const Title = styled.div`
    color: #000;
	font-family: 'Roboto Condensed', sans-serif, Arial;
	font-weight: 700;
	font-size: 14px;
    line-height: 16px;
    padding-bottom: 14px;
`;

const Link = styled.a`
    &:hover {
        text-decoration: underline;
    }
`;

const HeadingContent = styled.div`
	margin-top: 34px;
	margin-bottom: 34px;
`;

const RowDeatils = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
`;

const ColTile = styled.div`
	margin-right: 24px;
`;

const HeadingTitle = styled.h1`
	color: #000;
	font-family: 'Roboto Condensed', sans-serif, Arial;
	font-weight: 400;
	font-size: 22px;
	line-height: 24px;
`;

const ColSwitch = styled.div`
	margin-right: 58px;
`;

const Status = styled(HeadingTitle)`
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	margin-top: 5px;
`;

const ColInfo = styled.div`
`;

const SourceInfo = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
`;

const AppInfo = styled.div`
	margin-right: 36px;
`;

const SourceDetails = styled.div`
	display: flex;
`;

const Image = styled.img`
	width: 30px;
	height: 30px;
`;

const Connection = styled(Status)`
	text-align: left;
`;

const AppTitle = styled(Status)`
	align-self: center;
	font-size: 17px;
	line-height: 19px;
	margin-left: 24px;
	font-weight: 600;
`;

const Arrow = styled.div`
	margin-top: 7px;
	margin-right: 15px;
`;

const DestinationInfo = styled.div`
	margin-left: 30px;
	margin-right: 110px;
`;

const ColSettings = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const Configure = styled(HeadingTitle)`
	font-size: 13px;
	line-height: 15px;
	margin-top: 5px;
`;

const SyncInfo = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 24px;
`;

const Col = styled.div`
	margin-right: 67px;
`;

const RunningInfo = styled(HeadingTitle)`
	font-size: 14px;
	line-height: 16px;
	font-weight: 600;
`;

const IconContent = styled.div`
	cursor: pointer;
`;

// Get the Data
const REF_PIPELINE_LOG = gql`
    query($pipeline_id: Int!){
        log_pipeline (where:{pipeline_id :{_eq: $pipeline_id}}){
            id
            status
            activity_name
            triggered_by
        }
    }
`;

const REF_PIPELINE = gql`
	query($id: Int!){
		pipeline (where:{id :{_eq: $id}}) {
			id
			name
			description
			last_ran_at
			status
			source{
			  id
			  name
			  image_url
			}
			destination {
				name
			}
		}
	}
`;

const REF_PIPELINE_STATUS_UPDATE = gql`
	mutation update_pipeline($id: Int!,  $status:Boolean!) {
		update_pipeline(where: {id:{_eq: $id}}, _set: {status: $status}) {
			returning{
				id
				status
			}
		}
	}
`;

// Main Component
const PipelineView = (props) => {
	const { pipelineId } = props;

	const { loading, error, data } = useQuery(REF_PIPELINE_LOG, {
		variables: { pipeline_id: pipelineId },
	});

	const {
		loading: loadingPipeline,
		error: errorPipeline, data:
		dataPipeline
	} = useQuery(REF_PIPELINE, {
		variables: { id: pipelineId },
	});

	const [update_pipeline] = useMutation(REF_PIPELINE_STATUS_UPDATE);

	const pipelineLog = (data && data.log_pipeline) || [];
	const pipeline = (dataPipeline && dataPipeline.pipeline[0]) || {};
	const source = pipeline && pipeline.source;
	const destination = pipeline && pipeline.destination;
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		const pipelineData = dataPipeline && dataPipeline.pipeline[0];
		if (pipelineData) {
			setChecked(pipelineData.status);
		}
	}, [dataPipeline]);

	/* Handler Function */
	const handleChange = (isEnable) => {
		setChecked(isEnable);
		update_pipeline({ variables: { id: pipelineId, status: isEnable } });
	};

	const onClickConfigure = () => {
		navigate(`/pipeline/edit/${pipelineId}`);
	};

	if (loading || loadingPipeline) {
		return <Skeleton />;
	}

	if (error || errorPipeline) {
		return <ErrorMessage error={error || errorPipeline} />;
	}

	const { name, image_url } = source;

	return (
		<WrapperComp>
			<Layout
				tablist={tablist}
				activeMenu="pipeline"
			/>
			<Content>
				<HeadingContent>
					<RowDeatils>
						<ColTile>
							<HeadingTitle>{pipeline.name}</HeadingTitle>
						</ColTile>
						<ColSwitch>
							<Switch
								size="lg"
								isChecked={checked}
								onChange={handleChange}
							/>
							<Status>{checked ? 'Start' : 'Pause'}</Status>
						</ColSwitch>
						<ColInfo>
							<SourceInfo>
								<AppInfo>
									<SourceDetails>
										<Image src={image_url} alt={name} />
										<AppTitle>{name}</AppTitle>
									</SourceDetails>
									<Connection>App Connection</Connection>
								</AppInfo>
								<Arrow>
									<Icon
										fill="#000"
										width={18}
										height={18}
										name="arrow"
									/>
								</Arrow>
								<Arrow>
									<Icon
										fill="#000"
										width={18}
										height={18}
										name="arrow"
									/>
								</Arrow>
								<DestinationInfo>
									<SourceDetails>
										<Icon
											fill="#000"
											width={30}
											height={30}
											name="server"
										/>
										<AppTitle>{destination.name}</AppTitle>
									</SourceDetails>
									<Connection>Destination</Connection>
								</DestinationInfo>
							</SourceInfo>
						</ColInfo>
						<ColSettings>
							<IconContent onClick={onClickConfigure}>
								<Icon
									fill="#000"
									width={30}
									height={30}
									name="settings"
								/>
							</IconContent>
							<Configure>Configure</Configure>
						</ColSettings>
					</RowDeatils>
					<SyncInfo>
						<Col>
							<RunningInfo>Pipeline is running normally</RunningInfo>
						</Col>
						<Col>
							<RunningInfo>Next time this pipeline will run is in 03:59 hours</RunningInfo>
						</Col>
						<Col>
							<RunningInfo>Run Now</RunningInfo>
						</Col>
					</SyncInfo>
				</HeadingContent>
				<Title>Activity Moniter</Title>
				<Table>
					<Table.TableRow>
						<Table.TableHeader title="Activity" />
						<Table.TableHeader title="Time" />
						<Table.TableHeader title="Status" />
						<Table.TableHeader title="Logs" />
					</Table.TableRow>
					{pipelineLog.map((log) => (
						<Table.TableRow>
							<Table.TableContent>{log.activity_name}</Table.TableContent>
							<Table.TableContent>{moment(log.log_time).format('MM-DD-YYYY h:mm')}</Table.TableContent>
							<Table.TableContent>{log.status}</Table.TableContent>
							<Table.TableContent>
								<Link href="http://kickoff.metalimits.com:8080">View</Link>
							</Table.TableContent>
						</Table.TableRow>
					))}
				</Table>
			</Content>
		</WrapperComp>
	);
};

PipelineView.propTypes = {
	pipelineId: PropTypes.string,
};

PipelineView.defaultProps = {
	pipelineId: '',
};

export default React.memo(PipelineView, isEqual);
