// User Api
import BaseApi from './base';

class UserApi extends BaseApi {
	login(email, password) {
		if (!email || !password) {
			return Promise.reject(new Error('Invalid email or password'));
		}

		return this._client.auth()
			.signInWithEmailAndPassword(email, password)
            .catch((error) => { // eslint-disable-line
				return Promise.reject(error);
			});
	}

	signup(data) {
		const email = data.email.toLowerCase();
		let bAuthCreate = false;
		let user = null;

		// User is already exist in auth or not
		return this._client.auth()
			.createUserWithEmailAndPassword(email, data.password)
		// Create create if not exist
			.then((authRes) => {  // eslint-disable-line
				if (authRes && authRes.user && authRes.user.uid) {
					bAuthCreate = true;
					user = authRes.user;
				} else if (!user) {
					return Promise.reject(new Error('Error in signup user'));
				}
			})
            .then(() => { // eslint-disable-line
				if (bAuthCreate) {
					return this._client.auth()
						.signInWithEmailAndPassword(email, data.password);
				}

				return Promise.resolve(null);
			})
            .then(() => { // eslint-disable-line
				return Promise.resolve(user);
			})
			.catch((err) => Promise.reject(err));
	}

	logout() {
		return this._client.auth().signOut()
			.then(() => Promise.resolve({}))
            .catch(() => { // eslint-disable-line
				return Promise.resolve({});
			});
	}
}

export default new UserApi();
