// Client
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

class Client {
	constructor() {
		this._app = null;
	}

	initialize() {
		const firebaseConfig = {
			apiKey: 'AIzaSyAi_88mXIFshBflAniStvDpj4tqrGViu30',
			authDomain: 'kickoff19-237210.firebaseapp.com',
			databaseURL: 'https://kickoff19-237210.firebaseio.com',
			projectId: 'kickoff19-237210',
			storageBucket: 'kickoff19-237210.appspot.com',
			messagingSenderId: '567711982561',
		};

		this._app = firebase.initializeApp(firebaseConfig);
	}

	firestore() {
		return this._app.firestore();
	}

	auth() {
		return this._app.auth();
	}

	storage() {
		return this._app.storage();
	}
}

export default new Client();
