// Textarea Component
import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { Textarea } from '@chakra-ui/core';
import styled from 'styled-components';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;

    .text-area {
		width: 100%;
		height: 100%;
		min-height: 100%;
		color: #000;
        font-family: 'Roboto Condensed', sans-serif, Arial;
        font-weight: 400;
        font-size: 15px;
		line-height: 17px;
	}
`;

// Main Component
const TextareaComponent = (props) => {
	const {
		isDisabled, placeholder,
		value, onChange, name,
	} = props;

	const handleChange = (event) => {
		if (onChange) {
			onChange(event.target.value, event.target.name);
		}
	};

	return (
		<Wrapper>
			<Textarea
				isDisabled={isDisabled}
				placeholder={placeholder}
				className="text-area"
				value={value}
				name={name}
				onChange={handleChange}
			/>
		</Wrapper>
	);
};

TextareaComponent.propTypes = {
	placeholder: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string,
	isDisabled: PropTypes.bool,
	onChange: PropTypes.func,
};

TextareaComponent.defaultProps = {
	placeholder: '',
	value: '',
	name: '',
	isDisabled: false,
	onChange: null,
};

export default React.memo(TextareaComponent, isEqual);
