// Creater View Signup
import React, { useState } from 'react';
import styled from 'styled-components';
import isEqual from 'react-fast-compare';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import { options } from '../helpers/options';
import { tablist } from '../../../components/Layout/helpers/options';
import {
	SelectLeftView, SelectRightView, ConnectLeftView,
	ConnectRightView, ConfigureLeftView, ConfigureRightView,
} from './index';
import { Header } from '../../../components/Header';
import { Layout } from '../../../components/Layout';
import { Spinner } from '../../../components/Spinner';
import { ErrorMessage } from '../../../components/ErrorMessage';

const Wrapper = styled.div`
    width: 100%;
`;

const PageContent = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 100%;
    width: 100%;
`;

const PageColLeft = styled.div`
    background-color: #ffcc7a;
    width: 560px;
    flex-basis: 560px;
    min-height: 100%;
    overflow: hidden;
`;

const PageColLeftConnect = styled(PageColLeft)`
    background-color: #92d8ff;
`;

const PageColLeftConfigure = styled(PageColLeft)`
    background-color: #feb7b8;
`;

const PageColRight = styled.div`
	flex-grow: 1;
	padding: 36px 120px 106px 120px;
`;

const Content = styled.div`
	padding-top: 174px;
`;

// Get the Data
const REF_SOURCE = gql`
    query{
        ref_source {
            description
            group
            name
            image_url
            id
        }
    }
`;

// CreaterView Component
const CreaterView = (props) => {
	const { pipelineId } = props;

	const [step, setStep] = useState(pipelineId ? 3 : 1);
	const [activeMenu, setActiveMenu] = useState('source');
	const [activeItem, setactiveItem] = useState({});

	const { loading, error, data } = useQuery(REF_SOURCE);

	const onMenuItem = (identifier, menu) => {
		if (identifier === 'connect') {
			setActiveMenu(identifier);
			setStep(2);
			setactiveItem(menu);
		}

		if (identifier === 'configure') {
			setActiveMenu(identifier);
			setStep(3);
			setactiveItem(menu);
		}
	};

	const refSource = (data && data.ref_source) || [];

	const connectionInfo = (activeItem && activeItem.connectionInfo) || {};
	const configureInfo = (activeItem && activeItem.configureInfo) || {};
	const PageColLeftComp = activeMenu === 'connect' ? PageColLeftConnect  // eslint-disable-line
		: (activeMenu === 'configure' ? PageColLeftConfigure : PageColLeft); 

	if (loading) {
		return <Spinner />;
	}

	if (error) {
		return <ErrorMessage error={error} />;
	}

	return (
		<Wrapper>
			<Layout
				tablist={tablist}
				activeMenu="pipeline"
			/>
			<PageContent>
				<PageColLeftComp>
					{step === 1 && (
						<SelectLeftView
							title="Pick a Source"
							info="Now that you have created a destination redshift you can connect your app to save the date on the destination."
							img="/images/connect_footer.png"
						/>
					)}
					{step === 2 && (
						<ConnectLeftView
							title="Connect"
							info={connectionInfo.info}
							item={connectionInfo}
							img="/images/connect_view_footer.png"
						/>
					)}
					{step === 3 && (
						<ConfigureLeftView
							title="Configure"
							info={configureInfo.info}
							item={configureInfo}
							img="/images/configure_footer.png"
						/>
					)}
				</PageColLeftComp>
				<PageColRight>
					<Header
						activeMenu={activeMenu}
					/>
					<Content>
						{step === 1 && (
							<SelectRightView
								refSource={refSource}
								onMenuItem={onMenuItem}
							/>
						)}
						{step === 2 && (
							<ConnectRightView
								item={activeItem}
								onMenuItem={onMenuItem}
							/>
						)}
						{step === 3 && (
							<ConfigureRightView
								item={activeItem}
								options={options}
								onMenuItem={onMenuItem}
							/>
						)}
					</Content>
				</PageColRight>
			</PageContent>
		</Wrapper>
	);
};

CreaterView.propTypes = {
	pipelineId: PropTypes.string,
};

CreaterView.defaultProps = {
	pipelineId: '',
};

export default React.memo(CreaterView, isEqual);
