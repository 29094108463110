// Button Component
import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { Button } from '@chakra-ui/core';
import styled from 'styled-components';

const Wrapper = styled.div`
`;

const WrapperConnect = styled(Wrapper)`
    .button {
		width: 100%;
        background-color: #48a3ee;
        border-radius: 10px;
        color: #fff;
        font-family: 'Roboto Condensed', sans-serif, Arial;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        height: 28px;
    }
`;

const WrapperConnectView = styled(WrapperConnect)`
    .button {
        border-radius: 5px;
        height: 40px;
    }
`;

const WrapperHeader = styled(WrapperConnect)`
    .button {
        border-radius: 5px;
        font-size: 12px;
        line-height: 14px;
        height: 30px;
    }
`;

const WrapperQuery = styled(WrapperConnect)`
	margin-right: 10px;

    .button {
		width: 78px;
		border-radius: 0;
		height: 30px;
		background-color: rgba(102,136,153,.15);
		color: #333;
		font-size: 12px;
        line-height: 14px;
    }
`;

const WrapperExecute = styled(WrapperQuery)`
	margin-right: 0;

    .button {
		background-color: #48a3ee;
		color: #fff;
    }
`;

// Main Component
const ButtonComponent = (props) => {
	const {
		variant, isDisabled,
		title, onClick, rightIcon,
		leftIcon,
	} = props;

	let ButtonComp = Wrapper;

	if (variant === 'connect') {
		ButtonComp = WrapperConnect;
	} else if (variant === 'connect-view') {
		ButtonComp = WrapperConnectView;
	} else if (variant === 'header') {
		ButtonComp = WrapperHeader;
	} else if (variant === 'query-save') {
		ButtonComp = WrapperQuery;
	} else if (variant === 'query-execute') {
		ButtonComp = WrapperExecute;
	}

	return (
		<ButtonComp>
			<Button
				className="button"
				variant={variant}
				isDisabled={isDisabled}
				onClick={onClick}
				rightIcon={rightIcon}
				leftIcon={leftIcon}
			>
				{title}
			</Button>
		</ButtonComp>
	);
};

ButtonComponent.propTypes = {
	variant: PropTypes.oneOf(['connect', 'connect-view', 'header', 'query-save', 'query-execute']),
	title: PropTypes.string,
	rightIcon: PropTypes.string,
	leftIcon: PropTypes.string,
	isDisabled: PropTypes.bool,
	onClick: PropTypes.func,
};

ButtonComponent.defaultProps = {
	variant: 'connect',
	title: '',
	rightIcon: '',
	leftIcon: '',
	isDisabled: false,
	onClick: PropTypes.func,
};

export default React.memo(ButtonComponent, isEqual);
