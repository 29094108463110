// Text Component
import React from 'react';
import isEqual from 'react-fast-compare';
import { Spinner } from '@chakra-ui/core';
import styled from 'styled-components';

const WrapperComp = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

// Main Component
const SpinnerComponent = () => (
	<WrapperComp>
		<Spinner
			thickness="4px"
			speed="0.65s"
			emptyColor="gray.200"
			color="blue.500"
			size="xl"
		/>
	</WrapperComp>
);

export default React.memo(SpinnerComponent, isEqual);
