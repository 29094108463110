/* eslint-disable import/prefer-default-export */
// Options - Layout

const tablist = [
	{
		id: 'pipeline',
		title: 'Pipeline'
	},
	{
		id: 'destination',
		title: 'Destinations'
	}
];

export {
	tablist,
};
