// Avatar components
import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';
import { Avatar } from '@chakra-ui/core';

const Wrapper = styled.div`
    .avt {
        width: 38px;
        height: 38px;
        color: #fff;
        background-color: #08d7a6;

        .css-1wnrxjo {
            font-weight: 700;
        }
    }
`;

// Main Component
const AvatarComponent = (props) => {
	const { name, image } = props;

	return (
		<Wrapper>
			<Avatar
				name={name}
				src={image}
				size="sm"
				className="avt"
			/>
		</Wrapper>
	);
};

AvatarComponent.propTypes = {
	image: PropTypes.string,
	name: PropTypes.string,
};

AvatarComponent.defaultProps = {
	image: '',
	name: '',
};

export default React.memo(AvatarComponent, isEqual);
