// Options - Pipeline
const menuItems = [
	{
		step: 1,
		identifier: 'source',
		title: 'Select source',
	},
	{
		step: 2,
		identifier: 'connect',
		title: 'Connect',
	},
	{
		step: 3,
		identifier: 'configure',
		title: 'Configure',
	},
];

const salesConnectList = [
	{
		image_url: '/images/sales_force.png',
		name: 'Salesforce',
		url: 'https://www.salesforce.com/domainname',
		info: 'Affordable CRM',
		connectionInfo: {
			info: 'Salesforce let third party applications to connect to your account using OAuth2 protocol.',
			question1: 'What do i need?',
			info1: '1. A valid user account.',
			info2: '2. Admin level access.',
			question2: 'What to do at Salesforce?',
			info3: '1. Login in to your salesforce account.',
			info4: '2. Locate your sub domain. It be of the form https://www.salesforce.com/yourcompany',
		},
		configureInfo: {
			info: 'Salesforce has multiple entities and you can choose which entities you want to sync.',
			question1: 'What do i need?',
			info1: 'List of entities that. You might need your warhouse.',
		},
	},
	{
		image_url: '/images/pipe_drive.png',
		name: 'Pipedrive',
		info: 'To CRM in G2 crowd',
		connectionInfo: {
			info: 'Pipedrive let third party applications to connect to your account using OAuth2 protocol.',
			question1: 'What do i need?',
			info1: '1. A valid user account.',
			info2: '2. Admin level access.',
			question2: 'What to do at Pipedrive?',
			info3: '1. Login in to your pipedrive account.',
		},
		configureInfo: {
			info: 'Pipedrive has multiple entities and you can choose which entities you want to sync.',
			question1: 'What do i need?',
			info1: 'List of entities that. You might need your warhouse.',
		},
	},
	{
		image_url: '/images/copper.png',
		name: 'Copper CRM',
		info: 'New CRM',
		connectionInfo: {
			info: 'Copper CRM let third party applications to connect to your account using OAuth2 protocol.',
			question1: 'What do i need?',
			info1: '1. A valid user account.',
			info2: '2. Admin level access.',
			question2: 'What to do at Copper CRM?',
			info3: '1. Login in to your copper CRM account.',
		},
		configureInfo: {
			info: 'Copper CRM has multiple entities and you can choose which entities you want to sync.',
			question1: 'What do i need?',
			info1: 'List of entities that. You might need your warhouse.',
		},
	},
	{
		image_url: '/images/fresh_sales.png',
		name: 'Freshsales',
		info: 'Affordable CRM',
		connectionInfo: {
			info: 'Freshsales let third party applications to connect to your account using OAuth2 protocol.',
			question1: 'What do i need?',
			info1: '1. A valid user account.',
			info2: '2. Admin level access.',
			question2: 'What to do at Freshsales?',
			info3: '1. Login in to your freshsales account.',
		},
		configureInfo: {
			info: 'Freshsales has multiple entities and you can choose which entities you want to sync.',
			question1: 'What do i need?',
			info1: 'List of entities that. You might need your warhouse.',
		},
	}
];

const customerServiceList = [
	{
		image_url: '/images/fresh_desk.png',
		name: 'Freshdesk',
		info: 'Affordable CRM',
		connectionInfo: {
			info: 'Freshdesk let third party applications to connect to your account using OAuth2 protocol.',
			question1: 'What do i need?',
			info1: '1. A valid user account.',
			info2: '2. Admin level access.',
			question2: 'What to do at Freshdesk?',
			info3: '1. Login in to your freshdesk account.',
		},
		configureInfo: {
			info: 'Freshdesk has multiple entities and you can choose which entities you want to sync.',
			question1: 'What do i need?',
			info1: 'List of entities that. You might need your warhouse.',
		},
	},
	{
		image_url: '/images/hub_shot.png',
		name: 'Hubspot service',
		info: 'Affordable CRM',
		connectionInfo: {
			info: 'Hubspot let third party applications to connect to your account using OAuth2 protocol.',
			question1: 'What do i need?',
			info1: '1. A valid user account.',
			info2: '2. Admin level access.',
			question2: 'What to do at Hubspot service?',
			info3: '1. Login in to your hubspot service account.',
		},
		configureInfo: {
			info: 'Hubspot service has multiple entities and you can choose which entities you want to sync.',
			question1: 'What do i need?',
			info1: 'List of entities that. You might need your warhouse.',
		},
	},
	{
		image_url: '/images/intercom.png',
		name: 'Intercom',
		info: 'Affordable CRM',
		connectionInfo: {
			info: 'Intercom let third party applications to connect to your account using OAuth2 protocol.',
			question1: 'What do i need?',
			info1: '1. A valid user account.',
			info2: '2. Admin level access.',
			question2: 'What to do at Intercom?',
			info3: '1. Login in to your intercom account.',
		},
		configureInfo: {
			info: 'Intercom service has multiple entities and you can choose which entities you want to sync.',
			question1: 'What do i need?',
			info1: 'List of entities that. You might need your warhouse.',
		},
	},
	{
		image_url: '/images/front.png',
		name: 'Front',
		info: 'Affordable CRM',
		connectionInfo: {
			info: 'Front let third party applications to connect to your account using OAuth2 protocol.',
			question1: 'What do i need?',
			info1: '1. A valid user account.',
			info2: '2. Admin level access.',
			question2: 'What to do at Front?',
			info3: '1. Login in to your front account.',
		},
		configureInfo: {
			info: 'Front has multiple entities and you can choose which entities you want to sync.',
			question1: 'What do i need?',
			info1: 'List of entities that. You might need your warhouse.',
		},
	}
];

const options = [
	{
		title: 'Project',
		isChecked: false,
	},
	{
		title: 'Users',
		isChecked: true,
	},
	{
		title: 'Epics',
		isChecked: false,
	},
	{
		title: 'Issues',
		isChecked: true,
	},
	{
		title: 'Projects',
		isChecked: false,
	},
	{
		title: 'Bananas',
		isChecked: false,
	},
];

const syncList = [
	{
		lbl: 'Last one month',
		value: 'lastonemonth',
	},
	{
		lbl: 'Last one year',
		value: 'lastoneyear',
	},
	{
		lbl: 'Last one week',
		value: 'lastoneweek',
	},
];

const syncTimeList = [
	{
		lbl: 'Every Day',
		value: 'everyday',
	},
	{
		lbl: 'Every Week',
		value: 'everyweek',
	},
	{
		lbl: 'Every year',
		value: 'everyyear',
	},
];

export {
	menuItems,
	salesConnectList,
	customerServiceList,
	options,
	syncList,
	syncTimeList,
};
