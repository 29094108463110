/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/prop-types */
// Router
import React from 'react';
import { Router, Location, Redirect } from '@reach/router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { CreaterView } from './pages/pipelines/Creator';
import { Viewer, PipelineView } from './pages/pipelines/Viewer';
import { LoginPage } from './pages/Login';
import { SignupPage } from './pages/SignupPage';
import { Destination, DestinationEdit } from './pages/Destination';
import { Query } from './pages/Query';
import { Home } from './pages/Home';

const PageTransitionGroup = styled(TransitionGroup)`
`;

const PageRouter = styled(Router)`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	&.fade-enter > div {
		opacity: 0;
  		z-index: 1;
	}

	&.fade-enter-active > div {
		opacity: 1;
		transition: opacity 450ms ease-in;
	}
`;

const ProtectedRoute = ({ component: Component, ...rest }) => (
	localStorage.getItem('user_id') ? <Component {...rest} /> : <Redirect from="" to="/login" noThrow />
);

const PublicRoute = ({ component: Component, ...rest }) => (
	<Component {...rest} />
);

const FadeTransitionRouter = (props) => (
	<Location>
		{({ location }) => (
			<PageTransitionGroup>
				<CSSTransition key={location.key} classNames="fade" timeout={500}>
					<PageRouter location={location}>
						{props.children}
					</PageRouter>
				</CSSTransition>
			</PageTransitionGroup>
		)}
	</Location>
);

const AppRouter = () => (
	<FadeTransitionRouter>
		<ProtectedRoute path="/" default component={Home} />
		<PublicRoute path="/login" component={LoginPage} />
		<PublicRoute path="/signup" component={SignupPage} />
		<ProtectedRoute path="/destinations" component={Destination} />
		<ProtectedRoute path="/pipelines" component={Viewer} />
		<ProtectedRoute path="/pipelines-view/:pipelineId" component={PipelineView} />
		<ProtectedRoute path="/pipeline/create" component={CreaterView} />
		<ProtectedRoute path="/pipeline/edit/:pipelineId" component={CreaterView} />
		<ProtectedRoute path="/destinations/:dataSourceId" component={DestinationEdit} />
		<ProtectedRoute path="/queries/:dataSourceId/schema" component={Query} />
	</FadeTransitionRouter>
);

export default AppRouter;
