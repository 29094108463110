// Switch Component
import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { Switch } from "@chakra-ui/core";
import styled from 'styled-components';

const Wrapper = styled.div`
    .switch {
    }
`;

// Main Component
const SwitchCompnent = (props) => {
    const { isChecked, size, onChange } = props;

    /* Handler Function */
    const handleChange = (event) => {
		if (onChange) {
			onChange(event.target.checked);
		}
	};

	return (
		<Wrapper>
            <Switch 
                className="switch"
                size={size}
                isChecked={isChecked}
                onChange={handleChange}
            />
		</Wrapper>
	);
};

SwitchCompnent.propTypes = {
    isChecked: PropTypes.bool,
    size: PropTypes.string,
    onChange: PropTypes.func,
};

SwitchCompnent.defaultProps = {
    isChecked: false,
    size: '',
    onChange: null,
};

export default React.memo(SwitchCompnent, isEqual);
