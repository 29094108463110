/* eslint-disable react/jsx-props-no-spreading */
import {
	isNil, map, filter, some, includes, isFunction,
	noop,
} from 'lodash';
import React, {
	useState, useCallback, useMemo, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import styled from 'styled-components';

import useDataSourceSchema from '../../api/use_datasource_schema';
// import useImmutableCallback from '@/lib/hooks/useImmutableCallback';
// import LoadingState from '../items-list/components/LoadingState';

const SchemaContainer = styled.div`
	background: transparent;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
	z-index: 10;
	position: absolute;
	left: 15px;
	top: 0;
	right: 15px;
	bottom: 0;

	.schema-browser {
		overflow-x: hidden;
		border: none;
		margin-top: 10px;
		overflow-y: auto;
		height: 100%;

		.schema-loading-state {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}

		.collapse.in {
		  background: transparent;
		}

		.copy-to-editor {
			color: rgba(102,136,153,.1);
			cursor: pointer;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.table-open {
			padding: 0 22px 0 26px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			position: relative;
			font-size: 13px;
			line-height: 1.42857143;
			color: #767676;
	  
		  .copy-to-editor {
			display: none;
		  }

		  &:hover {
			background: rgba(102,136,153,.1);

			.copy-to-editor {
			display: flex;
			}
		  }
		}
	  }

	  .schema-control {
		display: flex;
		flex-wrap: nowrap;
		padding: 0;
	  
		.ant-btn {
		  height: auto;
		}
	  }

	  div.table-name {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: pointer;
		padding: 2px 22px 2px 10px;
		position: relative;
		font-size: 13px;
		line-height: 1.42857143;
		color: #767676;

		.copy-to-editor {
		  display: none;
		}

		&:hover {
		  background: fade(@redash-gray, 10%);
	  
		  .copy-to-editor {
			display: flex;
		  }
		}
	  }

	  input {
		width: 100%;
		height: 35px;
		padding: 6px 12px;
		background-color: #fff;
		background-image: none;
		border: 1px solid #e8e8e8;
		transition: all;
		transition-duration: 0s;
		transition-duration: .3s;
		resize: none;
		box-shadow: 0 0 0 40px transparent !important;
		border-radius: 2px;
		margin-right: 5px;
	  }

	  .ant-btn {
		background-color: rgba(102,136,153,.15);
		display: inline-block;
		margin-bottom: 0;
		font-weight: 400;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		touch-action: manipulation;
		cursor: pointer;
		background-image: none;
		border: 1px solid transparent;
		padding: 6px 12px;
		font-size: 13px;
		line-height: 1.42857143;
		border-radius: 2px;
		user-select: none;
	}

	.zmdi {
		display: inline-block;
		font-size: 14px;
		font-size: inherit;
		text-rendering: auto;
	}

	.ant-btn:not(.ant-btn-alt) {
		border: 0;
	}

	.ant-tooltip {
        display: none !important;
	}

	.fa {
		display: inline-block;
		font-size: 14px;
		font-size: inherit;
		text-rendering: auto;
	}
	
	.fa-table::before {
	}

	.fa-angle-double-right::before {
		content: "\F101";
	}

	.ant-input {
		color: #767676;
        font-family: 'Roboto Condensed', sans-serif, Arial;
        font-size: 14px;
		line-height: 16px;
	}
`;

const SchemaItemType = PropTypes.shape({
	name: PropTypes.string.isRequired,
	size: PropTypes.number,
	columns: PropTypes.arrayOf(PropTypes.string).isRequired,
});

const schemaTableHeight = 22;
const schemaColumnHeight = 18;

function SchemaItem({
	item, expanded, onToggle, onSelect, ...props
}) {
	const handleSelect = useCallback(
		(event, ...args) => {
			event.preventDefault();
			event.stopPropagation();
			onSelect(...args);
		},
		[onSelect]
	);

	if (!item) {
		return null;
	}

	return (
		<div {...props}>
			<div className="table-name" onClick={onToggle}>
				<i className="fa fa-table m-r-5" />
				<strong>
					<span title={item.name}>{item.name}</span>
					{!isNil(item.size) && (
						<span>
							{' '}
							(
							{item.size}
							)
						</span>
					)}
				</strong>
				<i
					className="fa fa-angle-double-right copy-to-editor"
					aria-hidden="true"
					onClick={(e) => handleSelect(e, item.name)}
				/>
			</div>
			{expanded && (
				<div>
					{map(item.columns, (column) => (
						<div key={column} className="table-open">
							{column}
							<i
								className="fa fa-angle-double-right copy-to-editor"
								aria-hidden="true"
								onClick={(e) => handleSelect(e, column)}
							/>
						</div>
					))}
				</div>
			)}
		</div>
	);
}

SchemaItem.propTypes = {
	item: SchemaItemType,
	expanded: PropTypes.bool,
	onToggle: PropTypes.func,
	onSelect: PropTypes.func,
};

SchemaItem.defaultProps = {
	item: null,
	expanded: false,
	onToggle: () => { },
	onSelect: () => { },
};

function SchemaLoadingState() {
	return (
		<div className="schema-loading-state">
			loading
		</div>
	);
}

export function SchemaList({
	loading, schema, expandedFlags, onTableExpand, onItemSelect
}) {
	const [listRef, setListRef] = useState(null);

	useEffect(() => {
		if (listRef) {
			listRef.recomputeRowHeights();
		}
	}, [listRef, schema, expandedFlags]);

	return (
		<div className="schema-browser">
			{loading && <SchemaLoadingState />}
			{!loading && (
				<AutoSizer>
					{({ width, height }) => (
						<List
							ref={setListRef}
							width={width}
							height={height}
							rowCount={schema.length}
							rowHeight={({ index }) => {
								const item = schema[index];
								const columnCount = expandedFlags[item.name] ? item.columns.length : 0;
								return schemaTableHeight + schemaColumnHeight * columnCount;
							}}
							rowRenderer={({ key, index, style }) => {
								const item = schema[index];
								return (
									<SchemaItem
										key={key}
										style={style}
										item={item}
										expanded={expandedFlags[item.name]}
										onToggle={() => onTableExpand(item.name)}
										onSelect={onItemSelect}
									/>
								);
							}}
						/>
					)}
				</AutoSizer>
			)}
		</div>
	);
}

export function applyFilterOnSchema(schema, filterString) {
	const filters = filter(filterString.toLowerCase().split(/\s+/), (s) => s.length > 0);

	// Empty string: return original schema
	if (filters.length === 0) {
		return schema;
	}

	// Single word: matches table or column
	if (filters.length === 1) {
		const nameFilter = filters[0];
		const columnFilter = filters[0];
		return filter(
			schema,
			(item) =>
				includes(item.name.toLowerCase(), nameFilter)
				|| some(item.columns, (column) => includes(column.toLowerCase(), columnFilter))
		);
	}

	// Two (or more) words: first matches table, seconds matches column
	const nameFilter = filters[0];
	const columnFilter = filters[1];
	return filter(
		map(schema, (item) => {
			if (includes(item.name.toLowerCase(), nameFilter)) {
				item = { ...item, columns: filter(item.columns, (column) => includes(column.toLowerCase(), columnFilter)) };
				return item.columns.length > 0 ? item : null;
			}
		})
	);
}

function useImmutableCallback(callback) {
	const callbackRef = useRef();
	callbackRef.current = isFunction(callback) ? callback : noop;

	return useCallback((...args) => callbackRef.current(...args), []);
}

export default function SchemaBrowser({
	dataSource,
	onSchemaUpdate,
	onItemSelect,
	options,
	onOptionsUpdate,
	...props
}) {
	const [schema, isLoading, refreshSchema] = useDataSourceSchema(dataSource);
	const [filterString, setFilterString] = useState('');
	const filteredSchema = useMemo(() => applyFilterOnSchema(options, filterString), [options, filterString]);
	const [handleFilterChange] = useDebouncedCallback(setFilterString, 500);
	const [expandedFlags, setExpandedFlags] = useState({});

	const handleSchemaUpdate = useImmutableCallback(onSchemaUpdate);

	useEffect(() => {
		setExpandedFlags({});
		handleSchemaUpdate(schema);
	}, [schema, handleSchemaUpdate]);

	if (schema.length === 0 && !isLoading) {
		return null;
	}

	function toggleTable(tableName) {
		setExpandedFlags({
			...expandedFlags,
			[tableName]: !expandedFlags[tableName],
		});
	}

	return (
		<SchemaContainer {...props}>
			<div className="schema-control">
				<Input
					className="m-r-5"
					placeholder="Search schema..."
					disabled={schema.length === 0}
					onChange={(event) => handleFilterChange(event.target.value)}
				/>
				<Button onClick={() => refreshSchema(true)}>
					refresh
				</Button>
			</div>
			<SchemaList
				loading={isLoading && schema.length === 0}
				schema={filteredSchema}
				expandedFlags={expandedFlags}
				onTableExpand={toggleTable}
				onItemSelect={onItemSelect}
			/>
		</SchemaContainer>
	);
}

SchemaBrowser.propTypes = {
	dataSource: PropTypes.object, // eslint-disable-line react/forbid-prop-types
	onSchemaUpdate: PropTypes.func,
	onItemSelect: PropTypes.func,
};

SchemaBrowser.defaultProps = {
	dataSource: null,
	onSchemaUpdate: () => { },
	onItemSelect: () => { },
};
