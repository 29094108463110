// App
import React from 'react';
import { ThemeProvider, CSSReset } from '@chakra-ui/core';

// for apollo client
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

import customTheme from '../../theme';
import GlobalStyle from '../Common/global_style';
import Router from '../../router';
import Client from '../../client';

const client = new ApolloClient({
	uri: 'https://kickoff.metalimits.com:8082/v1/graphql',
	headers: {
		'Content-Type': 'application/json',
		'x-hasura-admin-secret': 'Meta2020Limits',
	}
});

// Initialize Firebase
Client.initialize();

// App
const App = () => (
	<ApolloProvider client={client}>
		<ThemeProvider theme={customTheme}>
			<CSSReset />
			<Router />
			<GlobalStyle />
		</ThemeProvider>
	</ApolloProvider>
);

export default App;
