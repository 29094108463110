// Page Base Component
import React from 'react';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';

import { Layout } from '../../components/Layout';
import { tablist } from '../../components/Layout/helpers/options';

// PageWrapper
const PageWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: scroll;
`;

const PageContent = styled.div`
`;

// Main Component
const PageBase = () => (
	<PageWrapper>
		<Layout
			tablist={tablist}
			activeMenu=""
		/>
		<PageContent />
	</PageWrapper>
);

export default React.memo(PageBase, isEqual);
