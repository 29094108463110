// Header Component
import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';

import { menuItems } from '../../pages/pipelines/helpers/options';

const Wrapper = styled.div`
	width: 100%;
    height: 35px;
	top: 0;
	z-index: 10;
`;

const Content = styled.div`
`;

const Menu = styled.div`
	display: flex;
	justify-content: space-between;
`;

const MenuItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
	height: 36px;
	font-family: 'Roboto Condensed', sans-serif, Arial;
	font-size: 13px;
	line-height: 1px;
	font-weight: 700;
	color: #c5c9d0;
	cursor: pointer;
	text-decoration: none;
`;

const MenuItemActive = styled(MenuItem)`
	color: #262626;
`;

const Title = styled.div`
	font-family: 'Roboto Condensed', sans-serif, Arial;
	font-size: 14px;
	line-height: 22px;
	font-weight: 600;
	color: #c5c9d0;
`;

const TitleActive = styled(Title)`
	color: #000;
`;

const Step = styled.div`
	width: 22px;
	height: 22px;
	font-family: 'Roboto Condensed', sans-serif, Arial;
	font-size: 11px;
	line-height: 13px;
	font-weight: 700;
    border-radius: 22px;
    border: 1px solid;
	background-color: #fff;
	text-align: center;
	padding-top: 4px;
	margin-right: 12px;
`;

const StepActive = styled(Step)`
    background-color: #000;
    border: 0 none;
	color: #fff;
`;

// MenuItem Component
const MenuItemComponent = ({ activeMenu, menu }) => { // eslint-disable-line
	const MenuItemComp = activeMenu === menu.identifier ? MenuItemActive : MenuItem;
	const StepComp = activeMenu === menu.identifier ? StepActive : Step;
	const TitleComp = activeMenu === menu.identifier ? TitleActive : Title;

	return (
		<MenuItemComp
			key={menu.identifier}
		>
			<StepComp>{menu.step}</StepComp>
			<TitleComp>{menu.title}</TitleComp>
		</MenuItemComp>
	);
};

// Menu Component
const MenuComponent = ({ activeMenu }) => (
	<Menu>
		{menuItems.map((menu) =>
			<MenuItemComponent menu={menu} activeMenu={activeMenu} />)}
	</Menu>
);

// Header Component
const Header = (props) => {
	const { activeMenu } = props;

	return (
		<Wrapper>
			<Content>
				<MenuComponent activeMenu={activeMenu} />
			</Content>
		</Wrapper>
	);
};

Header.propTypes = {
	activeMenu: PropTypes.string,
};

Header.defaultProps = {
	activeMenu: '',
};

export default React.memo(Header, isEqual);
