// CheckBox Component
import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { Checkbox } from '@chakra-ui/core';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;

    .checkbox {
        .css-nlndso {
            width: 21px;
            height: 21px;
        }

        .css-6qsuox {
            color: #4a5568;
            font-family: 'Roboto Condensed', sans-serif, Arial;
            font-size: 15px;
            line-height: 17px;
            margin-left: 16px;
        }
    }
`;

// Main Component
const CheckBoxComponent = (props) => {
	const {
		isChecked, isDisabled, children,
		onChange,
	} = props;

	const handleChange = (event) => {
		if (onChange) {
			onChange(event.target.checked);
		}
	};

	return (
		<Wrapper>
			<Checkbox
				className="checkbox"
				isChecked={isChecked}
				isDisabled={isDisabled}
				onChange={handleChange}
				children={children}
			/>
		</Wrapper>
	);
};

CheckBoxComponent.propTypes = {
	children: PropTypes.string,
	isChecked: PropTypes.bool,
	isDisabled: PropTypes.bool,
	onChange: PropTypes.func,
};

CheckBoxComponent.defaultProps = {
	children: '',
	isChecked: false,
	isDisabled: false,
	onChange: null,
};

export default React.memo(CheckBoxComponent, isEqual);
