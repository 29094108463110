// Login Page Component
import React from 'react';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import { Login } from '../../components/Login';
import UserApi from '../../api/user';

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: scroll;
`;

const Content = styled.div`
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ImageContent = styled.div`
	position: relative;
`;

const Image = styled.img`
	width: 100%
	height: 100%;
	object-fit: cover;
`;

// Main Component
const LoginPage = () => {
	const onNext = (data) => {
		UserApi.login(data.email, data.password)
			.then((res) => {
				localStorage.setItem('user_id', (res && res.user && res.user.uid) || '');
				navigate('/');
			}).catch((err) => {
				console.log('Err - GetUser Info', err);
			});
	};

	return (
		<Wrapper>
			<Content>
				<ImageContent>
					<Image src="/images/login_page.jpg" />
				</ImageContent>
				<Login onNext={onNext} />
			</Content>
		</Wrapper>
	);
};

export default React.memo(LoginPage, isEqual);
