/* eslint-disable react/destructuring-assignment */
// Tabs Component
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import Popover, { ArrowContainer } from 'react-tiny-popover';

import { Button } from '../Button';
import { Avatar } from '../Avatar';
import { Icon } from '../Icon';
import UserApi from '../../api/user';

const Wrapper = styled.div`
    width: 100%;
    background: #fff;
	position: sticky;
	top: 0;
	z-index: 100;
`;

const Content = styled.div`
    display: flex;
    height: 66px;
    justify-content: flex-start;
    align-items: stretch;
	flex-direction: row;
    border-bottom: 1px solid #eceef0;
    padding: 12px 72px 0 72px;
`;

const ColImage = styled.div`
    margin-top: 3px;
    flex-grow: 1;
`;

const Image = styled.img`
    width: 28px;
    height: 28px;
`;

const Menu = styled.div`
`;

const MenuItem = styled.div`
    display: inline-block;
    height: 46px;
	font-family: 'Roboto Condensed', sans-serif, Arial;
	font-size: 13px;
	line-height: 15px;
	font-weight: 700;
	color: #abb1b6;
	cursor: pointer;
	text-decoration: none;
	margin-right: 30px;
`;

const MenuItemActive = styled(MenuItem)`
	color: #192734;
	border-bottom: 3px solid #2996f1;
`;

const ColButton = styled.div`
    margin-right: 36px;
`;

const ColProfile = styled.div`
	cursor:pointer;
`;

const ProfileContent = styled.div`
	display: flex;
	background: #435754;
	width: 170px;
	padding: 20px;
	border-radius: 10px;
	margin-right: 20px;
	box-shadow: 0px 0px 20px -14px rgba(0,0,0,0.75);
`;

const LabelProfile = styled.div`
	font-family: 'Roboto Condensed', sans-serif, Arial;
	font-size: 14px;
	align-self: center;
	line-height: 16px;
	font-weight: 600;
	color: #FFF;
	text-transform: uppercase;
	cursor: pointer;
	margin-right: 20px;
`;

const LogoutDetails = styled.div`
	display: flex;
`;

const IconContent = styled.div`
	cursor: pointer;
`;

// MenuItem Component
const MenuItemComponent = ({
	menu, activeMenu, onChange, index, // eslint-disable-line
}) => {
	const MenuItemComp = activeMenu === menu.id ? MenuItemActive : MenuItem; // eslint-disable-line

	const onMenuItem = () => {
		if (index === 0) {
			navigate('/pipelines');
		}

		if (index === 1) {
			navigate('/destinations');
		}
	};

	return (
		<MenuItemComp
			key={menu.id}
			onClick={onMenuItem}
		>
			{menu.title}
		</MenuItemComp>
	);
};

// Menu Component
const MenuComp = ({ tablist, activeMenu, onChange }) => { // eslint-disable-line
	return (
		<Menu>
			{tablist.map((menu, idx) => (
				<MenuItemComponent
					menu={menu}
					index={idx}
					activeMenu={activeMenu}
					onChange={onChange}
				/>
			))}
		</Menu>
	);
};

// Popover Component
const PopoverComp = ({ props }) => {
	const onLogout = () => {
		UserApi.logout()
			.then(() => {
				localStorage.removeItem('user_id');
				navigate('/login');
			}).catch((err) => {
				console.log('Err - Logout Info', err);
			});
	};

	return (
		<ArrowContainer
			position={props.position}
			targetRect={props.targetRect}
			popoverRect={props.popoverRect}
			arrowColor="rgb(18, 19, 19)"
			arrowSize={10}
			arrowStyle={{ opacity: 0.7 }}
		>
			<ProfileContent>
				<LogoutDetails onClick={onLogout}>
					<LabelProfile>Logout</LabelProfile>
					<IconContent>
						<Icon
							width={18}
							height={18}
							name="logout"
						/>
					</IconContent>
				</LogoutDetails>
			</ProfileContent>
		</ArrowContainer>
	);
};

// Layout Component
const Layout = (props) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const { tablist, activeMenu, onChange } = props;

	/* Handler Function */
	const onCreateSource = () => {
		navigate('/pipeline/create');
	};

	const onClickOutside = () => {
		setIsPopoverOpen(false);
	};

	const onClickProfile = (isOpen) => {
		setIsPopoverOpen(isOpen);
	};

	return (
		<Wrapper>
			<Content>
				<ColImage>
					<Image src="/images/header_connect.png" alt="" />
				</ColImage>
				<MenuComp
					tablist={tablist}
					activeMenu={activeMenu}
					onChange={onChange}
				/>
				<ColButton>
					<Button
						title="Create"
						leftIcon="add"
						variant="header"
						onClick={onCreateSource}
					/>
				</ColButton>
				<Popover
					containerClassName="tiny-popup"
					isOpen={isPopoverOpen}
					position={['bottom']}
					content={(data) => (<PopoverComp props={data} />)}
					onClickOutside={onClickOutside}
				>
					<ColProfile onClick={() => onClickProfile(!isPopoverOpen)}>
						<Avatar name="Dan Abrahmov" src="https://bit.ly/dan-abramov" />
					</ColProfile>
				</Popover>
			</Content>
		</Wrapper>
	);
};

Layout.propTypes = {
	tablist: PropTypes.array, // eslint-disable-line
	activeMenu: PropTypes.string,
};

Layout.defaultProps = {
	tablist: [],
	activeMenu: '',
};

export default React.memo(Layout, isEqual);
