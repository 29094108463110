/* eslint-disable consistent-return */
// Query Component
import React, { useState, useEffect } from 'react';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';
import { Table } from 'antd';
import 'antd/dist/antd.css';

import { Layout } from '../../components/Layout';
import { Button } from '../../components/Button';
import { tablist } from '../../components/Layout/helpers/options';
import { TextArea } from '../../components/TextArea';
import { getEditorComponents } from './editor-components';

import axios from '../../api/axios';

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: scroll;
`;

const PageContent = styled.div`
    display: flex;
    flex-grow: 1;
    min-height: 100%;
    margin-top: 20px;
`;

const Content = styled.div`
    background: #fff;
    border-right: 
    padding: 0;
    box-shadow: 0 4px 9px -3px rgba(102,136,153,.15);
    display: flex;
    flex-grow: 1;
    width: 33%;
    border-right: 1px solid #efefef;
`;

const NavigatorList = styled.div`
    display: flex;
    flex-flow: column;
    flex-basis: 100%;
    max-width: 100%;
    min-width: 10px;
    overflow-x: hidden;
    position: relative;
    flex-shrink: 0;
`;

const LeftSchema = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
`;

const MainContent = styled.div`
    background: #fff;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    align-content: space-around;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
`;

const FlexFill = styled.div`
    flex: 1 1 auto !important;
    position: relative !important;
`;

const RowContent = styled.div`
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    display: flex !important;
    flex-direction: column !important;
    osition: absolute !important;
    padding: 0 15px 0 15px;
`;

const RowEditor = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-content: space-around;
    overflow: hidden;
    flex: 0 0 300px;
    border-bottom: 1px solid #efefef;
    position: relative;
    background: #fff;
    z-index: 9;
    margin-right: -15px;
    margin-left: -15px;
`;

const TextContent = styled.div`
    width: 100%;
`;

const TableContent = styled.div`
	overflow-y: auto;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
`;

// Main Component
const Query = (props) => {
	const { dataSourceId } = props;
	const [state, setState] = useState({
		dataSource: null,
		schemas: [],
		query: '',
		queryResult: null,
		showTable: false
	});

	useEffect(() => {
		let source = null;

		axios
			.get(`/api/data_sources/${dataSourceId}`)
			.then((res) => {
				if (res.data) {
					source = res.data;
					return axios.get(`/api/data_sources/${dataSourceId}/schema`);
				}

				return Promise.resolve({});
			})
			.then((query) => {
				const { data } = query;
				if (data && data.schema.length !== 0) {
					setState((prevState) => ({
						...prevState,
						dataSource: source,
						schemas: data.schema
					}));
				}

				return Promise.resolve({});
			})
			.catch((err) => Promise.resolve(err));
	}, []);

	const onExecute = () => {
		const params = {
			data_source_id: dataSourceId,
			parameters: {},
			query: state.query,
			max_age: 0,
		};

		axios
			.post('api/query_results', params)
			.then((response) => {
				const { data } = response;
				if ('job' in data) {
					return axios.get(`api/jobs/${data.job.id}`);
				}
			})
			.then((info) => {
				const { data } = info;
				if ('job' in data && data.job.status === 2 && data.job.query_result_id === null) {
					return axios.get(`api/jobs/${data.job.id}`);
				}

				return Promise.resolve(info);
			})
			.then((res) => {
				const { data } = res;
				if ('job' in data && data.job.query_result_id !== null) {
					return axios.get(`api/query_results/${data.job.query_result_id}`);
				}
			})
			.then((queryResult) => {
				const { data } = queryResult;
				if (data && data.length !== 0) {
					setState((prevState) => ({
						...prevState,
						queryResult: data.query_result,
						showTable: true,
					}));
				}

				return Promise.resolve({});
			})
			.catch((error) => { console.log('error', error); });
	};

	const handleChange = (value, name) => {
		setState((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	const { dataSource } = state;
	const { SchemaBrowser } = getEditorComponents(dataSource && dataSource.type);
	const result = state.queryResult;
	const data = (result && result.data) || {};

	const columns = [];
	const rows = [];
	if (data && data.columns && data.columns.length !== 0) {
		data.columns.forEach((column) => {
			columns.push({
				title: column.name,
				dataIndex: column.name,
				key: column.name,
			});
		});
	}

	if (data && data.rows && data.rows.length !== 0) {
		data.rows.forEach((row) => {
			rows.push(row);
		});
	}

	return (
		<Wrapper>
			<Layout
				tablist={tablist}
				activeMenu="destination"
			/>
			<PageContent>
				<Content>
					<NavigatorList>
						<LeftSchema>
							<SchemaBrowser
								dataSource={dataSource}
								options={state.schemas}
							/>
						</LeftSchema>
					</NavigatorList>
				</Content>
				<MainContent>
					<FlexFill>
						<RowContent>
							<RowEditor>
								<TextContent>
									<TextArea
										value={state.query}
										name="query"
										onChange={handleChange}
									/>
								</TextContent>
							</RowEditor>
						</RowContent>
						<ButtonRow>
							<Button
								title="save"
								variant="query-save"
								leftIcon="check"
							/>
							<Button
								title="Execute"
								variant="query-execute"
								leftIcon="chevron-right"
								onClick={onExecute}
							/>
						</ButtonRow>
						<TableContent>
							{state.showTable && (
								<Table
									columns={columns}
									dataSource={rows}
									rowKey={(row) => row.key}
									bordered
								/>
							)}
						</TableContent>
					</FlexFill>
				</MainContent>
			</PageContent>
		</Wrapper>
	);
};

export default React.memo(Query, isEqual);
