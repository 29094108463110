// Login Component
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';

import { Input } from '../Input';
import { Button } from '../Button';

const WrapperForm = styled.div`
	position: absolute;
	padding: 30px;
    display: flex;
    flex-direction: column;
	background-color: #fff;
	box-shadow: 0 0 0 40px transparent !important;
`;

const Form = styled.div`
    min-width: 402px;
    max-width: 402px;
`;

const FormRow = styled.div`
	margin-bottom: 12px;
`;

const Heading = styled.h1`
    font-family: 'Roboto Condensed', sans-serif, Arial;
    font-weight: 600;
    font-size: 20px;
	letter-spacing: 8px;
	font-weight: 200;
	line-height: 22px;
	margin: 0 0 20px 0;
	text-align: center;
`;

// Main Component
const Login = (props) => {
	const { onNext } = props;
	const [state, setState] = useState({
		email: '',
		password: '',
	});

	const handleChange = (value, name) => {
		setState((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	const saveAndContinue = () => {
		if (onNext) {
			onNext(state);
		}
	};

	return (
		<WrapperForm>
			<Heading>Login to your account</Heading>
			<Form>
				<FormRow>
					<Input
						variant="connect"
						type="text"
						placeholder="email"
						name="email"
						value={state.email}
						onChange={handleChange}
					/>
				</FormRow>
				<FormRow>
					<Input
						variant="connect"
						type="password"
						placeholder="password"
						name="password"
						value={state.password}
						onChange={handleChange}
					/>
				</FormRow>
				<FormRow>
					<Button
						title="Login"
						variant="connect-view"
						onClick={saveAndContinue}
					/>
				</FormRow>
			</Form>
		</WrapperForm>
	);
};

Login.propTypes = {
	onNext: PropTypes.func,
};

Login.defaultProps = {
	onNext: null,
};

export default React.memo(Login, isEqual);
