// UseDataSourceSchema Api
import { has } from "lodash";
import {
	useCallback, useEffect, useRef, useState, useMemo 
} from 'react';
import axios from './axios';

function getSchema(dataSource, refresh = undefined) {
	if (!dataSource) {
		return Promise.resolve([]);
	  }
	
	  return axios.get(`api/data_sources/${dataSource.id}/schema`).then(res => {
		if (has(res, "data.schema")) {
			return res.data.schema || [];
		}

		return Promise.reject();
	  })
	  .catch((err) => {
		return Promise.resolve([]);
	  });
}

export default function useDataSourceSchema(dataSource) {
	const [schema, setSchema] = useState([]);
	const [loadingSchema, setLoadingSchema] = useState(true);
	const refreshSchemaTokenRef = useRef(null);

	const reloadSchema = useCallback(
		(refresh = undefined) => {
			setLoadingSchema(true);
			const refreshToken = Math.random()
				.toString(36)
				.substr(2);
			refreshSchemaTokenRef.current = refreshToken;
			getSchema(dataSource, refresh)
				.then((data) => {
					if (refreshSchemaTokenRef.current === refreshToken) {
						setSchema(data);
					}
				})
				.finally(() => {
					if (refreshSchemaTokenRef.current === refreshToken) {
						setLoadingSchema(false);
					}
				});
		},
		[dataSource]
	);

	useEffect(() => {
		reloadSchema();
	}, [reloadSchema]);

	useEffect(() => () => {
		// cancel pending operations
		refreshSchemaTokenRef.current = null;
	}, []);

	return useMemo(() => [schema, loadingSchema, reloadSchema], [schema, loadingSchema, reloadSchema]);
}
