// Select Component
import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { Select } from '@chakra-ui/core';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;

    .select {
        border-color: #cbd5e0;
        color: #717780;
        border-radius: 6px;
    }
`;

// Main Component
const SelectCompnent = (props) => {
	const { options } = props;

	return (
		<Wrapper>
			<Select className="select">
				{options.map((opt) =>
					<option value={opt.value}>{opt.lbl}</option>)}
			</Select>
		</Wrapper>
	);
};

SelectCompnent.propTypes = {
    options: PropTypes.array // eslint-disable-line
};

SelectCompnent.defaultProps = {
	options: []
};

export default React.memo(SelectCompnent, isEqual);
