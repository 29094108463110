// Input Component
import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import {
	Input, InputGroup, Icon, InputLeftElement
} from '@chakra-ui/core';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;

    .input {
        background-color: #f4f5f6;
        font-size: 14px;
        border-radius: 8px;
		border: 0 none;
    }
`;

const WrapperCompConnect = styled(Wrapper)`
    .input {
        background-color: transparent;
        border: 1px solid #cbd5e0;
        border-radius: 3px;
        color: #718096;
        font-family: 'Roboto Condensed', sans-serif, Arial;
        font-weight: 600;
        font-size: 15px;
		line-height: 17px;
		height: 50px;
    }
`;

const WrapperDataSource = styled(WrapperCompConnect)`
    .input {
		height: 35px;
		color: #595959;
		font-size: 13px;
		line-height: 15px;
		font-weight: 500;
    }
`;

// Main Component
const InputComponent = (props) => {
	const {
		variant, isDisabled, placeholder,
		isShowIcon, value, onChange,
		type, name,
	} = props;

	let WrapperComp = Wrapper;

	if (variant === 'connect') {
		WrapperComp = WrapperCompConnect;
	} else if (variant === 'data-source') {
		WrapperComp = WrapperDataSource;
	}

	const handleChange = (event) => {
		if (onChange) {
			onChange(event.target.value, event.target.name);
		}
	};

	return (
		<WrapperComp>
			<InputGroup>
				{isShowIcon && <InputLeftElement children={<Icon name="search" color="gray.300" />} />}
				<Input
					isDisabled={isDisabled}
					placeholder={placeholder}
					className="input"
					value={value}
					name={name}
					type={type}
					onChange={handleChange}
				/>
			</InputGroup>
		</WrapperComp>
	);
};

InputComponent.propTypes = {
	variant: PropTypes.oneOf(['normal', 'connect', 'data-source']),
	placeholder: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.oneOf(['text', 'password']),
	isDisabled: PropTypes.bool,
	isShowIcon: PropTypes.bool,
	onChange: PropTypes.func,
};

InputComponent.defaultProps = {
	variant: 'normal',
	placeholder: '',
	value: '',
	name: '',
	type: 'text',
	isDisabled: false,
	isShowIcon: false,
	onChange: null,
};

export default React.memo(InputComponent, isEqual);
