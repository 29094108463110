// Text Component
import React from 'react';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from "@chakra-ui/core";

const WrapperComp = styled.div`
    width: 100%;
    height: 100%;
`;

const DataSourceContent = styled.div`
    border-radius: 3px;
    box-shadow: 0 4px 9px -3px rgba(102,136,153,.15);
    background-color: #fff !important;
`;

const Title = styled.div`
    color: #000;
	font-family: 'Roboto Condensed', sans-serif, Arial;
	font-weight: 700;
	font-size: 20px;
    line-height: 22px;
    padding-bottom: 40px;
`;

const Content = styled.div`
    padding: 15px;
`;

const Row = styled.div`
`;

const VisualCardList = styled.div`
    width: 100%;
    margin: -5px 0 0 -5px;
`;

const VisualCard = styled.div`
    background: #FFFFFF;
    border: 1px solid rgba(102, 136, 153, .15);
    border-radius: 3px;
    margin: 5px;
    width: 212px;
    padding: 15px 5px;
    cursor: pointer;
    box-shadow: none;
    transition: transform 0.12s ease-out;
    transition-duration: 0.3s;
    transition-property: box-shadow;
    display: flex;
    align-items: center;

    &:hover {
        box-shadow: rgba(102, 136, 153, 0.15) 0px 4px 9px -3px;
    }
`;

const SourceContent = styled.div`
    display: flex;
    flex-grow: 1;
`;

const Image = styled.img`
    width: 64px;
    height: 64px;
    margin-right: 5px;
`;

const Name = styled.h3`
    align-self: center;
    font-size: 13px;
    color: #323232;
    font-family: 'Roboto Condensed', sans-serif, Arial;
    margin: 0 !important;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const IconeContent = styled.div`
`;

// Main Component
const DataSources = (props) => {
    const { dataSource, onClick, onClickQuery } = props;

    if (!dataSource) {
        return null;
    }

    /* Handler Function */
    const onClickCard = () => {
        if (onClickQuery) {
            onClickQuery(dataSource.id)
        }
    }

    const onClickEdit = () => {
        if (onClick) {
            onClick(dataSource.id)
        }
    }

    return (
        <WrapperComp>
            <Title>Data Sources</Title>
            <DataSourceContent>
                <Content>
                    <Row>
                        <VisualCardList>
                            <VisualCard>
                                <SourceContent onClick={onClickCard}>
                                    <Image alt={dataSource.name} src={dataSource.imgSrc} />
                                    <Name>{dataSource.name}</Name>
                                </SourceContent>
                                <IconeContent onClick={onClickEdit}>
                                    <Icon name="edit" />
                                </IconeContent>
                            </VisualCard>
                        </VisualCardList>
                    </Row>
                </Content>
            </DataSourceContent>
        </WrapperComp>
    );
};

DataSources.propTypes = {
    dataSource: PropTypes.object,
    onClickQuery: PropTypes.func,
    onClick: PropTypes.func,
};

DataSources.defaultProps = {
    dataSource: null,
    onClickQuery: null,
    onClick: null,
};

export default React.memo(DataSources, isEqual);
