// Login Page Component
import React from 'react';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import UserApi from '../../api/user';

import { Signup } from '../../components/Signup';

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: scroll;
`;

const Content = styled.div`
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

// Main Component
const SignupPage = () => {
	// Create Account
	const onNext = (data) => {
		UserApi.signup(data)
			.then(() => {
				navigate('/');
			}).catch((err) => {
				console.log('Err - GetUser Info', err);
			});
	};

	return (
		<Wrapper>
			<Content>
				<Signup onNext={onNext} />
			</Content>
		</Wrapper>
	);
};

SignupPage.propTypes = {
};

SignupPage.defaultProps = {
};

export default React.memo(SignupPage, isEqual);
