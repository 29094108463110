/* eslint-disable array-callback-return */
// Select Right View Component
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEqual from 'react-fast-compare';

import { Text } from '../../../components/Text';
import { Button } from '../../../components/Button';
import { Input } from '../../../components/Input';

const Wrapper = styled.div`
    width: 100%
`;

const Content = styled.div`
`;

const InputRow = styled.div`
    margin-top: 20px;
    margin-bottom: 66px;
`;

const SalesInfo = styled.div`
    margin-bottom: 82px;
`;

const Title = styled.div`
    color: #4c5a67;
	font-family: 'Roboto Condensed', sans-serif, Arial;
	font-weight: 500;
	font-size: 20px;
    line-height: 22px;
    padding-bottom: 40px;
`;

const ContentInfo = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 634px;
`;

const Info = styled.div`
    display: flex;
    width: 300px;
    padding-bottom: 42px;
`;

const Image = styled.div`
    width: 50px;
    height: 50px;
    margin-right: 18px;
`;

const Details = styled.div`
`;

const DetailsTitle = styled.h1`
    color: #192734;
    font-family: 'Roboto Condensed', sans-serif, Arial;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
`;

const DetailsInfo = styled.p`
    color: #4c5a67;
    font-family: 'Roboto Condensed', sans-serif, Arial;
    font-size: 12px;
    line-height: 14px;
    padding-top: 4px;
`;

const ButtonRow = styled.div`
    padding-top: 12px;
`;

const SelectRightView = (props) => {
	const { refSource, onMenuItem } = props;

	const onClickMenuItem = (menu) => {
		if (onMenuItem) {
			onMenuItem('connect', menu);
		}
	};

	const salesConnectList = [];
	const customerServiceList = [];

	if (refSource.length !== 0) {
		refSource.map((source) => {
			if (source.group === 'Sales & Marketing') {
				salesConnectList.push(source);
			} else if (source.group === 'Customer service') {
				customerServiceList.push(source);
			}
		});
	}

	return (
		<Wrapper>
			<Text title="Select a source" />
			<InputRow>
				<Input
					placeholder="Search"
					isShowIcon
				/>
			</InputRow>
			<Content>
				<SalesInfo>
					<Title>Sales &amp; Marketing</Title>
					<ContentInfo>
						{salesConnectList.map((menu) => (
							<Info>
								<Image>
									<img src={menu.image_url} alt="img" />
								</Image>
								<Details>
									<DetailsTitle>{menu.name}</DetailsTitle>
									<DetailsInfo>{menu.description}</DetailsInfo>
									<ButtonRow>
										<Button
											title="Connect"
											variant="connect"
											onClick={() => onClickMenuItem(menu)}
										/>
									</ButtonRow>
								</Details>
							</Info>
						))}
					</ContentInfo>
				</SalesInfo>
				<SalesInfo>
					<Title>Customer service</Title>
					<ContentInfo>
						{customerServiceList.map((menu) => (
							<Info>
								<Image>
									<img src={menu.image_url} alt="img" />
								</Image>
								<Details>
									<DetailsTitle>{menu.name}</DetailsTitle>
									<DetailsInfo>{menu.description}</DetailsInfo>
									<ButtonRow>
										<Button
											title="Connect"
											variant="connect"
											onClick={() => onClickMenuItem(menu)}
										/>
									</ButtonRow>
								</Details>
							</Info>
						))}
					</ContentInfo>
				</SalesInfo>
			</Content>
		</Wrapper>
	);
};

SelectRightView.propTypes = {
	refSource: PropTypes.array, // eslint-disable-line
	onMenuItem: PropTypes.func,
};

SelectRightView.defaultProps = {
	refSource: [],
	onMenuItem: null,
};

export default React.memo(SelectRightView, isEqual);
