// Signup Component
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';
import validator from 'validator';

import { Input } from '../Input';
import { Button } from '../Button';

const WrapperForm = styled.div`
    min-height: 520px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 80px 0 200px 0;
    min-height: 400px;
`;

const Heading = styled.h1`
    font-family: 'Roboto Condensed', sans-serif, Arial;
    font-weight: 600;
    font-size: 34px;
	letter-spacing: 8px;
	font-weight: 200;
	line-height: 34px;
	margin: 0 0 20px 0;
`;

const Form = styled.form`
    width: 430px;
`;

const FormRow = styled.div`
	margin-bottom: 15px;
`;

// Main Component
const Signup = (props) => {
	const { onNext } = props;

	const [state, setState] = useState({
		email: '',
		name: '',
		password: '',
		confirmPassword: ''
	});

	const handleChange = (value, name) => {
		setState((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	const saveAndContinue = () => {
		let noError = 0;

		if (validator.isEmpty(state.email) || !validator.isEmail(state.email)) {
			noError++;
		}

		if (validator.isEmpty(state.name)) {
			noError++;
		}

		if (validator.isEmpty(state.password)) {
			noError++;
		}

		if (validator.isEmpty(state.confirmPassword)) {
			noError++;
		}

		if (state.password !== state.confirmPassword) {
			noError++;
		}

		if (noError === 0) {
			if (onNext) {
				onNext(state);
			}
		}
	};

	return (
		<WrapperForm>
			<Content>
				<Heading>Create account</Heading>
				<Form onSubmit={saveAndContinue}>
					<FormRow>
						<Input
							placeholder="Email Address"
							type="text"
							name="email"
							variant="connect"
							value={state.email}
							onChange={handleChange}
						/>
					</FormRow>
					<FormRow>
						<Input
							placeholder="User Name"
							type="text"
							name="name"
							variant="connect"
							value={state.name}
							onChange={handleChange}
						/>
					</FormRow>
					<FormRow>
						<Input
							placeholder="Password"
							type="password"
							name="password"
							variant="connect"
							value={state.password}
							onChange={handleChange}
						/>
					</FormRow>
					<FormRow>
						<Input
							placeholder="Confirm password"
							type="password"
							name="confirmPassword"
							variant="connect"
							value={state.confirmPassword}
							onChange={handleChange}
						/>
					</FormRow>
					<FormRow>
						<Button
							title="Submit"
							variant="connect-view"
							onClick={saveAndContinue}
						/>
					</FormRow>
				</Form>
			</Content>
		</WrapperForm>
	);
};

Signup.propTypes = {
	onNext: PropTypes.func,
};

Signup.defaultProps = {
	onNext: null,
};

export default React.memo(Signup, isEqual);
