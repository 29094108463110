// Cofigure Left View Component
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEqual from 'react-fast-compare';

import { Text } from '../../../components/Text';

const Wrapper = styled.div`
    width: 100%
`;

const ColLeftPick = styled.div`
`;

const Content = styled.div`
    padding: 48px 0 0 94px;
`;

const ImageRow = styled.div`
    width: 50px;
    height: 50px;
`;

const RowInfo = styled.div`
    padding-top: 146px;
    max-width: 356px;
    padding-bottom: 36px;
`;

const Title = styled.div`
    color: #c8636f;
	font-family: 'Roboto Condensed', sans-serif, Arial;
	font-weight: 500;
	font-size: 26px;
    line-height: 28px;
    padding-bottom: 34px;
`;

const AppInfo = styled.div`
    background: #fff;
    width: 366px;
    padding: 30px 0 18px 22px;
    border-radius: 10px;
`;

const Questions = styled.div`
    color: #000;
    font-family: 'Roboto Condensed', sans-serif, Arial;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 14px;
`;

const Answers = styled.div`
    color: #000;
    font-family: 'Roboto Condensed', sans-serif, Arial;
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 8px;
`;

const Row = styled.div`
`;

const ConfigureLeftView = (props) => {
	const {
		info, title, item, img
	} = props;

	return (
		<Wrapper>
			<ColLeftPick>
				<Content>
					<ImageRow>
						<img src="/images/configure_view.png" alt="connect" />
					</ImageRow>
					<RowInfo>
						<Title>{title}</Title>
						<Text title={info} varient="configure" />
					</RowInfo>
					<AppInfo>
						<Row>
							<Questions>{item.question1}</Questions>
							<Answers>{item.info1}</Answers>
							<Answers>{item.info2}</Answers>
						</Row>
					</AppInfo>
				</Content>
			</ColLeftPick>
		</Wrapper>
	);
};

ConfigureLeftView.propTypes = {
	info: PropTypes.string,
	title: PropTypes.string,
	img: PropTypes.string,
	item: PropTypes.object, // eslint-disable-line
};

ConfigureLeftView.defaultProps = {
	info: '',
	title: '',
	img: PropTypes.string,
	item: null,
};

export default React.memo(ConfigureLeftView, isEqual);
